import {createReducer, on} from '@ngrx/store';
import {ProduktEntitiesState} from '../states/produkt-entities.state';
import {produktEntitiesAdapter} from '../adapters/produkt-entities.adapter';
import {produktEntitiesMock} from '../../mocks/data/produkt-entities.mock';
import {ProduktEntitiesActions} from '../actions/produkt-entities.actions';


export const initialProduktEntitiesState: ProduktEntitiesState = produktEntitiesAdapter.getInitialState({
  isLoading: false,
  entities: produktEntitiesMock.entities,
  ids: produktEntitiesMock.ids,
  count: produktEntitiesMock.ids.length,
  nextProduktnummer: 1, // FIXME: mock entfernen
  // einheiten: einheitenMock, // FIXME: mock entfernen
  // umsatzsteuersaetze: umsatzsteuersaetzeMock, // FIXME: mock entfernen
  // berechnungsarten: berechnungsartenMock, //FIXME: mock entfernen
});

export const produktEntitiesReducer = createReducer(
  initialProduktEntitiesState,

  on(
    ProduktEntitiesActions.getProduktByIdSuccess,
    (state, action) => {
      return produktEntitiesAdapter.upsertOne(action.produktDto, state);
    }
  ),

  on(
    ProduktEntitiesActions.readNextProduktnummerSuccess,
    (state, action) => ({
      ...state,
      nextProduktnummer: action.produktnummer,
    })
  ),
);

