import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/states/app.state';
import {FormInput} from '../../components/form/form-input.interface';
import {take} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {KundenFormControls} from './kunden-form.controls';
import {
  RadioButton
} from '@adnova/jf-ng-components/lib/generic/form-elements/form-fields/radio/radio-button/radio-button.interface';
import {KundeDialogActions} from '../../../store/actions/kunde-dialog.actions';
import {
  KundePrivatpersonFormControls
} from '../../components/forms/kunde-privatperson-form/kunde-privatperson-form.controls';
import {
  KundeGeschaeftskundeFormControls
} from '../../components/forms/kunde-geschaeftskunde-form/kunde-geschaeftskunde-form.interface';
import {KundeDialogSelectors} from '../../../store/selectors/kunde-dialog.selectors';
import {
  AdresseDTO,
  GeschaeftskundendatenDTO,
  KundeDTO,
  KundeRequestDTO,
  PrivatkundendatenDTO,
} from '../../../openapi/fakturierung-openapi';
import {FormFieldSelectValue, InhaberEntitiesSelectors, OptionComponent} from '@adnova/jf-ng-components';
import {KundeEntitiesSelectors} from '../../../store/selectors/kunde-entities.selectors';
import {AnredenDialogData} from '../../../interfaces/anreden-data.interface';
import {KundenFormType} from './kunden-form.type';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {KundeEntitiesActions} from '../../../store/actions/kunde-entities.actions';
import {LandEntitiesSelectors} from '../../../store/selectors/land-entities.selectors';
import {LandEntitiesActions} from '../../../store/actions/land-entities.actions';
import {map} from 'rxjs/operators';


@Component({
  selector: 'app-kunde-dialog',
  templateUrl: './kunde-dialog.component.html',
  styleUrls: ['./kunde-dialog.component.scss']
})
export class KundeDialogComponent implements OnInit {

  private _store = inject(Store<AppState>);
  private _destroyRef = inject(DestroyRef);

  private _anredenData: AnredenDialogData[] = [
    {
      label: 'Frau',
      isSelected: false,
    },
    {
      label: 'Herr',
      isSelected: false,
    },
    {
      label: 'Nicht zutreffend',
      isSelected: false,
    },
  ];
  private _addToInvoice: boolean = false;
  private _laenderGeschaeft: OptionComponent[] = [];
  private _laenderPrivat: OptionComponent[] = [];
  private _anreden: OptionComponent[] = [];
  private _selectedAbsenderType?: string;
  private _kundenFormType = KundenFormType.CREATE;
  private _primaryButtonLabel = 'Kontakt anlegen';
  private _kundeDto?: KundeDTO;
  private _formControlsPrivat: KundePrivatpersonFormControls = {
    anrede: new FormControl(null),
    titel: new FormControl(''),
    kundennummer: new FormControl(null, [Validators.required]),
    vorname: new FormControl('', [Validators.required]),
    nachname: new FormControl('', [Validators.required]),
    strasseHausnummer: new FormControl('', [Validators.required]),
    plz: new FormControl('', [Validators.required]),
    ort: new FormControl('', [Validators.required]),
    landPrivat: new FormControl(null, [Validators.required]),
    email: new FormControl('', [Validators.email]),
  };
  private _formControlsGeschaeft: KundeGeschaeftskundeFormControls = {
    firma: new FormControl('', [Validators.required]),
    kundennummer: new FormControl(null, [Validators.required]),
    strasseHausnummer: new FormControl('', [Validators.required]),
    plz: new FormControl('', [Validators.required]),
    ort: new FormControl('', [Validators.required]),
    landGeschaeft: new FormControl(null, [Validators.required]),
    ustId: new FormControl(''),
    email: new FormControl('', [Validators.email]),
    ansprechpartnerAnrede: new FormControl(null),
    ansprechpartnerTitel: new FormControl(''),
    ansprechpartnerVorname: new FormControl(''),
    ansprechpartnerNachname: new FormControl(''),
  };
  private _kundendatenTypes: RadioButton[] = [
    {
      label: 'Geschäftskunde',
      value: 'Geschaeftskundendaten',
      id: 'Geschaeftskundendaten',
      isSelected: true,
    },
    {
      label: 'Privatperson',
      value: 'Privatkundendaten',
      id: 'Privatkundendaten',
      isSelected: false,
    },
  ];
  private _formInputs: FormInput = {
    title: 'Neuen Kontakt anlegen',
    cancelButtonLabel: 'Abbrechen',
  };
  private _formControls: KundenFormControls = {
    kundendatenType: new FormControl(null),
    privat: new FormGroup(this._formControlsPrivat),
    geschaeft: new FormGroup(this._formControlsGeschaeft),
  };
  private _formGroup = new FormGroup(this._formControls);
  private _betriebId = '';

  get kundenFormType(): KundenFormType {
    return this._kundenFormType;
  }

  get laenderGeschaeft(): OptionComponent[] {
    return this._laenderGeschaeft;
  }

  get laenderPrivat(): OptionComponent[] {
    return this._laenderPrivat;
  }

  get anreden(): OptionComponent[] {
    return this._anreden;
  }

  get selectedAbsenderType(): string | undefined {
    return this._selectedAbsenderType;
  }

  get primaryButtonLabel(): string {
    return this._primaryButtonLabel;
  }

  get kundeDto(): KundeDTO | undefined {
    return this._kundeDto;
  }

  get formControlsPrivat(): KundePrivatpersonFormControls {
    return this._formControlsPrivat;
  }

  get formControlsGeschaeft(): KundeGeschaeftskundeFormControls {
    return this._formControlsGeschaeft;
  }

  get kundendatenTypes(): RadioButton[] {
    return this._kundendatenTypes;
  }

  get formInputs(): FormInput {
    return this._formInputs;
  }

  get formControls(): KundenFormControls {
    return this._formControls;
  }

  ngOnInit() {
    this._store.select(LandEntitiesSelectors.laenderLoaded).pipe(
      takeUntilDestroyed(this._destroyRef),
    ).subscribe(laenderLoaded => {
      if (laenderLoaded) return;

      this._store.dispatch(LandEntitiesActions.readLaender());
    });

    this._store.select(LandEntitiesSelectors.all).pipe(
      takeUntilDestroyed(this._destroyRef),
    ).subscribe(landDtos => {
      if (!landDtos) return;

      for (const landDto of landDtos) {
        const option = new OptionComponent();
        option.id = landDto.kurzform;
        option.label = landDto.kurzform;
        option.isSelected = !!(this._kundeDto && this._kundeDto.adresse.land === option.label);

        this._laenderPrivat.push(option);
        this._laenderGeschaeft.push(option);
      }
    });

    for (const anrede of this._anredenData) {
      const anredeOption = new OptionComponent();
      anredeOption.id = anrede.label;
      anredeOption.label = anrede.label;
      anredeOption.isSelected = anrede.isSelected;

      this._anreden.push(anredeOption);
    }

    // INFO: Handling für das Befüllen der Controls
    this._store.select(KundeDialogSelectors.kundeId).pipe(
      take(1),
    ).subscribe(kundeId => {
      if (kundeId) {
        // INFO: Wenn eine Kunden-ID vorhanden ist, werden die Daten in das Formular geladen.

        this._store.select(KundeEntitiesSelectors.kundenById(kundeId)).pipe(
          take(1),
        ).subscribe(kundeDto => {
          if (!kundeDto) return;

          this._formInputs.title = 'Kontakt bearbeiten';
          this._primaryButtonLabel = 'Speichern';
          this._kundenFormType = KundenFormType.EDIT;
          this._kundeDto = kundeDto;

          if (kundeDto.kundendaten.typ) {
            this._kundendatenTypes.map(kundendatenType => {
              kundendatenType.isSelected = kundendatenType.id === kundeDto.kundendaten.typ;
            });
          }

          if (kundeDto.kundendaten.typ === 'Privatkundendaten') {
            this.formControls.privat.get('kundennummer')?.setValue(kundeDto?.kundennummer);

            const kundendatenDto = kundeDto.kundendaten as PrivatkundendatenDTO;
            this.formControls.privat.get('titel')?.setValue(kundendatenDto?.titel);
            this.formControls.privat.get('anrede')?.setValue(kundendatenDto?.anrede);
            this.formControls.privat.get('vorname')?.setValue(kundendatenDto?.vorname);
            this.formControls.privat.get('nachname')?.setValue(kundendatenDto?.nachname);
            this.formControls.privat.get('email')?.setValue(kundendatenDto?.emailAdresse);

            const adresseDto = kundeDto.adresse;
            this.formControls.privat.get('strasseHausnummer')?.setValue(adresseDto?.strasseHausnummer);
            this.formControls.privat.get('plz')?.setValue(adresseDto?.postleitzahl);
            this.formControls.privat.get('ort')?.setValue(adresseDto?.ort);

            this._anreden.map(anrede => {
              anrede.isSelected = anrede.label === kundendatenDto?.anrede;
            });
          } else {
            this.formControls.geschaeft.get('kundennummer')?.setValue(kundeDto.kundennummer);

            const kundendatenDto = kundeDto.kundendaten as GeschaeftskundendatenDTO;
            this.formControls.geschaeft.get('firma')?.setValue(kundendatenDto.firmenbezeichnung);
            this.formControls.geschaeft.get('ustId')?.setValue(kundendatenDto.ustId);
            this.formControls.geschaeft.get('email')?.setValue(kundendatenDto.emailAdresse);

            const ansprechpartnerDto = kundendatenDto.ansprechpartner;
            this.formControls.geschaeft.get('ansprechpartnerTitel')?.setValue(ansprechpartnerDto?.titel);
            this.formControls.geschaeft.get('ansprechpartnerAnrede')?.setValue(ansprechpartnerDto?.anrede);
            this.formControls.geschaeft.get('ansprechpartnerVorname')?.setValue(ansprechpartnerDto?.vorname);
            this.formControls.geschaeft.get('ansprechpartnerNachname')?.setValue(ansprechpartnerDto?.nachname);

            const adresseDto = kundeDto.adresse;
            this.formControls.geschaeft.get('strasseHausnummer')?.setValue(adresseDto.strasseHausnummer);
            this.formControls.geschaeft.get('plz')?.setValue(adresseDto.postleitzahl);
            this.formControls.geschaeft.get('ort')?.setValue(adresseDto.ort);

            this._anreden.map(anrede => {
              anrede.isSelected = anrede.label === ansprechpartnerDto?.anrede;
            });
          }
        });
      } else {
        // INFO: Wenn keine Kunden-ID vorhanden ist, wird die nächste Kundennummer für einen neuen Kunden ermittelt.

        this._store.select(InhaberEntitiesSelectors.currentInhaberId).pipe(
          take(1),
        ).subscribe(inhaberId => {
          if (!inhaberId) return;

          this._betriebId = inhaberId;

          this._store.dispatch(KundeDialogActions.readNextKundennummer({
            betriebId: inhaberId,
          }));
        });

        // INFO: Handling für die Kundennummer
        this._store.select(KundeDialogSelectors.nextKundennummer).pipe(
          takeUntilDestroyed(this._destroyRef),
        ).subscribe(kundennummer => {
          if (!kundennummer) return;

          // INFO: Wenn keine Kundennummer vorhanden ist, kann der Anwender diese frei wählen / eingeben.
          this.formControls.privat.get('kundennummer')?.setValue(kundennummer);
          this.formControls.geschaeft.get('kundennummer')?.setValue(kundennummer);
        });
      }
    });

    this._selectedAbsenderType = this._kundendatenTypes.find(type => type.isSelected)?.value;

    this.formControls.kundendatenType.valueChanges.pipe(
      takeUntilDestroyed(this._destroyRef),
    ).subscribe(value => {
      if (!value || !value.value) return;
      this._selectedAbsenderType = value.value;
    });

    this.formControls.geschaeft.valueChanges.pipe(
      takeUntilDestroyed(this._destroyRef),
    ).subscribe(value => {
      this.updatePrivateFormValueChanges(value);
    });

    this.formControls.privat.valueChanges.pipe(
      takeUntilDestroyed(this._destroyRef),
    ).subscribe(value => {
      this.updateGeschaeftFormValueChanges(value);
    });

    this._store.select(KundeDialogSelectors.addToInvoice).pipe(
      takeUntilDestroyed(this._destroyRef),
    ).subscribe(addToInvoice => {
      this._addToInvoice = addToInvoice || false;
    });

    // INFO: Schließen des Dialogs, wenn der Erstell- oder Speicher-Vorgang erfolgreich war.
    this._store.select(KundeEntitiesSelectors.createSaveActionSuccessful).pipe(
      takeUntilDestroyed(this._destroyRef),
    ).subscribe(successful => {
      if (!successful) return;
      this._store.dispatch(KundeDialogActions.close());
    });

    // INFO: Prüfen, ob die USt-IdNr. Pflichtfeld ist.
    this._formControls.geschaeft.get('landGeschaeft')?.valueChanges.pipe(
      takeUntilDestroyed(this._destroyRef),
      map(value => value as FormFieldSelectValue),
    ).subscribe(value => {
      // INFO: Die Id der gewählten Option entspricht der Bezeichnung des Landes.
      const id = value.selectedOptionValueIds?.[0];

      // INFO: Sofern das gewählte Land nicht Deutschland ist, so muss die USt-IdNr. Pflichtfeld sein.
      const ustIdFormField = this._formControls.geschaeft.get('ustId');
      if (id === 'Deutschland') {
        ustIdFormField?.setValidators(null);
      } else {
        ustIdFormField?.setValidators([Validators.required]);
      }
      ustIdFormField?.updateValueAndValidity();
    });
  }

  /**
   * Aktualisiert die Werte des Geschäftskunden-Formulars, wenn sich die Werte des Privatpersonen-Formulars ändern.
   *
   * @param value
   * @private
   */
  private updateGeschaeftFormValueChanges(value: any): void {
    const newAnredeValue = value.anrede;
    const oldAnredeValue = this._formControls.privat.get('ansprechpartnerAnrede')?.value;
    if (newAnredeValue !== oldAnredeValue) {
      let anrede = this._formControls.geschaeft.get('ansprechpartnerAnrede')!;
      anrede.setValue(newAnredeValue);
      anrede.markAsTouched();
    }

    const newTitelValue = value.titel;
    const oldTitelValue = this._formControls.geschaeft.get('ansprechpartnerTitel')?.value;
    if (newTitelValue !== oldTitelValue) {
      let titel = this._formControls.geschaeft.get('ansprechpartnerTitel')!;
      titel.setValue(newTitelValue);
      titel.markAsTouched();
    }

    const newKundennummerValue = value.kundennummer;
    const oldKundennummerValue = this._formControls.geschaeft.get('kundennummer')?.value;
    if (newKundennummerValue !== oldKundennummerValue) {
      let kundennummer = this._formControls.geschaeft.get('kundennummer')!;
      kundennummer.setValue(newKundennummerValue);
      kundennummer.markAsTouched();
    }

    const newVornameValue = value.vorname;
    const oldVornameValue = this._formControls.geschaeft.get('ansprechpartnerVorname')?.value;
    if (newVornameValue !== oldVornameValue) {
      let vorname = this._formControls.geschaeft.get('ansprechpartnerVorname')!;
      vorname.setValue(newVornameValue);
      vorname.markAsTouched();
    }

    const newNachnameValue = value.nachname;
    const oldNachnameValue = this._formControls.geschaeft.get('ansprechpartnerNachname')?.value;
    if (newNachnameValue !== oldNachnameValue) {
      let nachname = this._formControls.geschaeft.get('ansprechpartnerNachname')!;
      nachname.setValue(newNachnameValue);
      nachname.markAsTouched();
    }

    const newStrasseHausnummerValue = value.strasseHausnummer;
    const oldStrasseHausnummerValue = this._formControls.geschaeft.get('strasseHausnummer')?.value;
    if (newStrasseHausnummerValue !== oldStrasseHausnummerValue) {
      let strasseHausnummer = this._formControls.geschaeft.get('strasseHausnummer')!;
      strasseHausnummer.setValue(newStrasseHausnummerValue);
      strasseHausnummer.markAsTouched();
    }

    const newPlzValue = value.plz;
    const oldPlzValue = this._formControls.geschaeft.get('plz')?.value;
    if (newPlzValue !== oldPlzValue) {
      let plz = this._formControls.geschaeft.get('plz')!;
      plz.setValue(newPlzValue);
      plz.markAsTouched();
    }

    const newOrtValue = value.ort;
    const oldOrtValue = this._formControls.geschaeft.get('ort')?.value;
    if (newOrtValue !== oldOrtValue) {
      let ort = this._formControls.geschaeft.get('ort')!;
      ort.setValue(newOrtValue);
      ort.markAsTouched();
    }

    const newLandGeschaeftValue = value.landPrivat;
    const oldLandGeschaeftValue = this._formControls.geschaeft.get('landGeschaeft')?.value;
    if (newLandGeschaeftValue !== oldLandGeschaeftValue) {
      let landGeschaeft = this._formControls.geschaeft.get('landGeschaeft')!;
      landGeschaeft.setValue(newLandGeschaeftValue);
      landGeschaeft.markAsTouched();
    }

    const newEmailValue = value.email;
    const oldEmailValue = this._formControls.geschaeft.get('email')?.value;
    if (newEmailValue !== oldEmailValue) {
      let email = this._formControls.geschaeft.get('email')!;
      email.setValue(newEmailValue);
      email.markAsTouched();
    }
  }

  /**
   * Aktualisiert die Werte des Privatpersonen-Formulars, wenn sich die Werte des Geschäftskunden-Formulars ändern.
   *
   * @param value
   * @private
   */
  private updatePrivateFormValueChanges(value: any): void {
    const newAnredeValue = value.ansprechpartnerAnrede;
    const oldAnredeValue = this._formControls.privat.get('anrede')?.value;
    if (newAnredeValue !== oldAnredeValue) {
      let anrede = this._formControls.privat.get('anrede')!;
      anrede.setValue(newAnredeValue);
      anrede.markAsTouched();
    }

    const newTitelValue = value.ansprechpartnerTitel;
    const oldTitelValue = this._formControls.privat.get('titel')?.value;
    if (newTitelValue !== oldTitelValue) {
      let titel = this._formControls.privat.get('titel')!;
      titel.setValue(newTitelValue);
      titel.markAsTouched();
    }

    const newKundennummerValue = value.kundennummer;
    const oldKundennummerValue = this._formControls.privat.get('kundennummer')?.value;
    if (newKundennummerValue !== oldKundennummerValue) {
      let kundennummer = this._formControls.privat.get('kundennummer')!;
      kundennummer.setValue(newKundennummerValue);
      kundennummer.markAsTouched();
    }

    const newVornameValue = value.ansprechpartnerVorname;
    const oldVornameValue = this._formControls.privat.get('vorname')?.value;
    if (newVornameValue !== oldVornameValue) {
      let vorname = this._formControls.privat.get('vorname')!;
      vorname.setValue(newVornameValue);
      vorname.markAsTouched();
    }

    const newNachnameValue = value.ansprechpartnerNachname;
    const oldNachnameValue = this._formControls.privat.get('nachname')?.value;
    if (newNachnameValue !== oldNachnameValue) {
      let nachname = this._formControls.privat.get('nachname')!;
      nachname.setValue(newNachnameValue);
      nachname.markAsTouched();
    }

    const newStrasseHausnummerValue = value.strasseHausnummer;
    const oldStrasseHausnummerValue = this._formControls.privat.get('strasseHausnummer')?.value;
    if (newStrasseHausnummerValue !== oldStrasseHausnummerValue) {
      let strasseHausnummer = this._formControls.privat.get('strasseHausnummer')!;
      strasseHausnummer.setValue(newStrasseHausnummerValue);
      strasseHausnummer.markAsTouched();
    }

    const newPlzValue = value.plz;
    const oldPlzValue = this._formControls.privat.get('plz')?.value;
    if (newPlzValue !== oldPlzValue) {
      let plz = this._formControls.privat.get('plz')!;
      plz.setValue(newPlzValue);
      plz.markAsTouched();
    }

    const newOrtValue = value.ort;
    const oldOrtValue = this._formControls.privat.get('ort')?.value;
    if (newOrtValue !== oldOrtValue) {
      let ort = this._formControls.privat.get('ort')!;
      ort.setValue(newOrtValue);
      ort.markAsTouched();
    }

    const newLandPrivatValue = value.landGeschaeft;
    const oldLandPrivatValue = this._formControls.privat.get('landPrivat')?.value;
    if (newLandPrivatValue !== oldLandPrivatValue) {
      let landPrivat = this._formControls.privat.get('landPrivat')!;
      landPrivat.setValue(newLandPrivatValue);
      landPrivat.markAsTouched();
    }

    const newEmailValue = value.email;
    const oldEmailValue = this._formControls.privat.get('email')?.value;
    if (newEmailValue !== oldEmailValue) {
      let email = this._formControls.privat.get('email')!;
      email.setValue(newEmailValue);
      email.markAsTouched();
    }
  }

  doConfirmClicked(): void {
    const kundeRequestDto: KundeRequestDTO = this.createKundeRequestDto();

    if (this.kundenFormType === KundenFormType.CREATE) {
      this._store.dispatch(KundeEntitiesActions.createKunde({
        betriebId: this._betriebId!,
        requestDto: kundeRequestDto,
        addToInvoice: this._addToInvoice,
      }));

    } else if (this.kundenFormType === KundenFormType.EDIT) {
      this._store.dispatch(KundeEntitiesActions.updateKunde({
        betriebId: this._betriebId!,
        kundeId: this.kundeDto!.id,
        requestDto: kundeRequestDto,
      }));
    }
  }

  /**
   * Erstellt ein KundeRequestDTO-Objekt aus den Werten des Formulars.
   *
   * @private
   */
  private createKundeRequestDto(): KundeRequestDTO {
    if (this._selectedAbsenderType === 'Privatkundendaten') {
      const formControlsPrivat = this._formControlsPrivat;

      const strasseHausnummer = formControlsPrivat.strasseHausnummer.value || '';
      const postleitzahl = formControlsPrivat.plz.value || '';
      const ort = formControlsPrivat.ort.value || '';

      const landId = formControlsPrivat.landPrivat.value?.selectedOptionValueIds![0];
      const land = this._laenderPrivat.find(land => land.id === landId)?.label || '';

      const adresseDto: AdresseDTO = {
        strasseHausnummer,
        postleitzahl,
        ort,
        land,
      };

      const anredeId = formControlsPrivat.anrede.value?.selectedOptionValueIds![0];
      const anrede = this._anreden.find(anrede => anrede.id === anredeId)?.label || '';

      const titel = formControlsPrivat.titel.value || '';
      const vorname = formControlsPrivat.vorname.value || '';
      const nachname = formControlsPrivat.nachname.value || '';
      const emailAdresse = formControlsPrivat.email.value || '';

      const privatkundendatenDto: PrivatkundendatenDTO = {
        anrede,
        titel,
        vorname,
        nachname,
        emailAdresse,
        typ: 'Privatkundendaten',
      };

      const kundennummer = formControlsPrivat.kundennummer.value || 0;

      return {
        kundennummer,
        adresse: adresseDto,
        kundendaten: privatkundendatenDto,
      };

    } else {
      const formControlsGeschaeft = this._formControlsGeschaeft;

      const strasseHausnummer = formControlsGeschaeft.strasseHausnummer.value || '';
      const postleitzahl = formControlsGeschaeft.plz.value || '';
      const ort = formControlsGeschaeft.ort.value || '';

      const selectedLandPrivatId = formControlsGeschaeft.landGeschaeft.value?.selectedOptionValueIds![0];
      const land = this._laenderGeschaeft.find(land => land.id === selectedLandPrivatId)?.label || '';

      const adresseDto: AdresseDTO = {
        strasseHausnummer,
        postleitzahl,
        ort,
        land,
      };

      const firmenbezeichnung = formControlsGeschaeft.firma.value || '';
      const ustId = formControlsGeschaeft.ustId.value || '';
      const emailAdresse = formControlsGeschaeft.email.value || '';

      const anredeId = formControlsGeschaeft.ansprechpartnerAnrede.value?.selectedOptionValueIds![0];
      const anrede = this._anreden.find(anrede => anrede.id === anredeId)?.label || '';

      const titel = formControlsGeschaeft.ansprechpartnerTitel.value || '';
      const vorname = formControlsGeschaeft.ansprechpartnerVorname.value || '';
      const nachname = formControlsGeschaeft.ansprechpartnerNachname.value || '';

      const geschaeftskundendatenDto: GeschaeftskundendatenDTO = {
        firmenbezeichnung,
        ustId,
        emailAdresse,
        ansprechpartner: {
          anrede,
          titel,
          vorname,
          nachname,
        },
        typ: 'Geschaeftskundendaten',
      };

      const kundennummer = formControlsGeschaeft.kundennummer.value || 0;

      return {
        kundennummer,
        adresse: adresseDto,
        kundendaten: geschaeftskundendatenDto,
      };
    }
  }

  protected closeDialogClick(): void {
    this._store.dispatch(KundeDialogActions.close());
  };

  /**
   * Überprüft, ob das Formular vollständig ist. Wenn folgende Bedingungen erfüllt sind, wird true zurückgegeben:
   * 1. Es muss geprüft werden, ob der ausgewählte Absender-Typ verändert worden ist.
   * 2. Es muss geprüft werden, ob die Werte des Privatpersonen-Formulars verändert wurden und valide sind.
   * 3. Es muss geprüft werden, ob die Werte des Geschäftskunden-Formulars verändert wurden und valide sind.
   *
   * @protected
   */
  protected checkFormComplete(): boolean {
    return (this._kundeDto && this._formGroup.controls.kundendatenType.dirty)
      || (this._selectedAbsenderType === 'Privatkundendaten'
        && this._formGroup.controls.privat.valid
        && this._formGroup.controls.privat.dirty)
      || (this._selectedAbsenderType === 'Geschaeftskundendaten'
        && this._formGroup.controls.geschaeft.valid
        && this._formGroup.controls.geschaeft.dirty);
  }

}

