import {AppState} from '../states/app.state';
import {identity} from 'rxjs';
import {createSelector} from '@ngrx/store';


export class KundeDialogSelectors {

  /**
   * Die `select`-Eigenschaft ist ein Objekt, das Funktionen definiert, jede Funktion selektiert eine
   * bestimmte Eigenschaft aus dem State.
   * Das sind grundlegende Selektoren, die im ganzen Projekt als Bausteine für komplexere Selektoren wiederverwendet
   * werden können.
   */
  private static select = {
    isOpen: (state: AppState) => state.kundenDialog.isOpen,
    kundeId: (state: AppState) => state.kundenDialog.kundeId,
    addToIncoice: (state: AppState) => state.kundenDialog.addToInvoice,
    nextKundennummer: (state: AppState) => state.kundenDialog.nextKundennummer,
  };

  public static isKundeDialogOpen = createSelector(
    this.select.isOpen,
    identity,
  );

  public static kundeId = createSelector(
    this.select.kundeId,
    identity,
  );

  public static addToInvoice = createSelector(
    this.select.addToIncoice,
    identity,
  );

  public static nextKundennummer = createSelector(
    this.select.nextKundennummer,
    identity,
  );
}
