import {KundeDialogState} from '../states/kunde-dialog.state';
import {KundeDialogActions} from '../actions/kunde-dialog.actions';
import {createReducer, on} from '@ngrx/store';


export const initialKundeDialogState: KundeDialogState = {
  isOpen: false,
  kundeId: undefined,
  addToInvoice: undefined,
};

export const kundeDialogReducer = createReducer(
  initialKundeDialogState,

  on(
    KundeDialogActions.open,
    (state, action) => ({
      ...state,
      isOpen: true,
      kundeId: action.kundeId,
      addToInvoice: action.addToInvoice ?? undefined,
    })
  ),

  on(
    KundeDialogActions.close,
    () => ({
      ...initialKundeDialogState,
    })
  ),

  on(KundeDialogActions.readNextKundennummerSuccess, (state, {nextKundennummer}) => ({
    ...state,
    nextKundennummer,
  })),

  on(KundeDialogActions.readNextKundennummerFailure, (state) => ({
    ...state,
    nextKundennummer: undefined,
  })),
);
