<div
  class='formfield-wrapper'
  *ngIf="formControls && einheitenOptions && berechnungsarten && umsatzsteuersaetze"
>
  <!--    Titel-->
  <jf-form-field-text
    class="title"
    [label]="'Produkt'"
    [formControl]="formControls.produktBezeichnung"
  ></jf-form-field-text>

  <!--    Einheit-->
  <jf-form-field-select
    class="einheit"
    [label]="'Einheit'"
    [formControl]="formControls.einheit"
    [options]="einheitenOptions"
  ></jf-form-field-select>

  <!--    Preis-->
  <jf-form-field-number
    class="preis"
    [label]="'Preis in €'"
    [formControl]="formControls.betragssumme"
    [thousandSeparator]="'.'"
    [truncate]="2"
    [decimalSeparator]="','"
    [fillWithZeros]="true"
  ></jf-form-field-number>

  <!--    Preisangabe In-->
  <jf-form-field-select
    class="berechnungsart"
    [label]="'Preisberechnung in:'"
    [formControl]="formControls.berechnungsart"
    [options]="berechnungsarten"
  ></jf-form-field-select>

  <!--    Umsatztsteuer Satz-->
  <jf-form-field-select
    class="ustSatz"
    [label]="'Umsatzsteuersatz'"
    [formControl]="formControls.ustProzentsatz"
    [options]="umsatzsteuersaetze"
  ></jf-form-field-select>

  <!--    Beschreibung-->
  <jf-form-field-text
    class="beschreibung"
    [formControl]="formControls.produktbeschreibung"
    [label]="'Produktbeschreibung'"
    [message]="'Produkt und Produktbeschreibung werden auf der Rechnung angezeigt'"
  ></jf-form-field-text>

  <!--    Produktnummer-->
  <jf-form-field-number
    class="produktnummer"
    [label]="'Produktnummer'"
    [formControl]="formControls.produktnummer"
  ></jf-form-field-number>
</div>
