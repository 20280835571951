<mat-dialog-content>
  <app-form
    [formInput]="formInputs"
    (cancelClicked)="closeDialogClick()"
  >
    <div *ngIf="kundeDto" class="header-content">
      Änderungen an bestehenden Kontaktdaten führen automatisch zur Aktualisierung der zugehörigen
      <b>Rechnungsentwürfe</b>.
    </div>
    <div
      class='formfield-wrapper body-content'>
      <jf-radio-button-group
        [formControl]="formControls.kundendatenType"
        [options]="kundendatenTypes"
        *ngIf="kundendatenTypes"
      ></jf-radio-button-group>

      <app-kunde-privatperson-form
        *ngIf="selectedAbsenderType && selectedAbsenderType === 'Privatkundendaten'"
        [formControls]="formControlsPrivat"
        [laenderOptions]="laenderPrivat"
        [anredenOptions]="anreden"
        [formType]="kundenFormType"
      ></app-kunde-privatperson-form>

      <app-kunde-geschaeftskunde-form
        *ngIf="selectedAbsenderType && selectedAbsenderType === 'Geschaeftskundendaten'"
        [formControls]="formControlsGeschaeft"
        [laenderOptions]="laenderGeschaeft"
        [anredenOptions]="anreden"
        [formType]="kundenFormType"
      ></app-kunde-geschaeftskunde-form>
    </div>

    <div
      class='footer-content'>
      <jf-button
        (click)="doConfirmClicked()"
        [style]="'primary'"
        [disabled]="!checkFormComplete()"
      >
        {{ primaryButtonLabel }}
      </jf-button>
    </div>
  </app-form>
</mat-dialog-content>
