import {KundeEntitiesState} from '../states/kunde-entities.state';
import {kundeEntitiesAdapter} from '../adapters/kunde-entities.adapter';
import {createReducer, on} from '@ngrx/store';
import {KundeEntitiesActions} from '../actions/kunde-entities.actions';
import {KundeTableActions} from '../actions/kunde-table.actions';
import {KundeDialogActions} from '../actions/kunde-dialog.actions';
import {DeleteKundeDialogActions} from '../actions/delete-kunde-dialog.actions';


export const initialKundeEntitiesState: KundeEntitiesState = kundeEntitiesAdapter.getInitialState({
  isLoading: false,
  totalElements: 0,
  createSaveActionSuccessful: false,
  deleteActionSuccessful: false,
});

export const kundeEntitiesReducer = createReducer(
  initialKundeEntitiesState,

  on(KundeTableActions.readKunden, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(KundeEntitiesActions.readKundenSuccess, (state, {kundeDtos}) => {

    return kundeEntitiesAdapter.upsertMany(
      kundeDtos,
      {
        ...state,
        isLoading: false,
      },
    );
  }),

  on(KundeEntitiesActions.readKundenFailed, (state) => ({
    ...state,
    isLoading: false,
  })),

  on(KundeEntitiesActions.countKundenElementsSuccess, (state, {totalElements}) => ({
    ...state,
    totalElements,
  })),

  on(KundeEntitiesActions.createKunde, (state) => ({
    ...state,
    createSaveActionSuccessful: false,
  })),

  on(KundeEntitiesActions.createKundeSuccess, (state, {kundeDto}) => {
    return kundeEntitiesAdapter.upsertOne(
      kundeDto,
      {
        ...state,
        totalElements: state.totalElements + 1,
        createSaveActionSuccessful: true,
      },
    );
  }),

  on(KundeEntitiesActions.updateKunde, (state) => ({
    ...state,
    createSaveActionSuccessful: false,
  })),

  on(KundeEntitiesActions.updateKundeSuccess, (state, {kundeDto}) => {
    return kundeEntitiesAdapter.updateOne(
      {
        id: kundeDto.id,
        changes: kundeDto,
      },
      {
        ...state,
        createSaveActionSuccessful: true,
      }
    );
  }),

  on(KundeEntitiesActions.deleteKunde, (state) => ({
    ...state,
    deleteActionSuccessful: false,
  })),

  on(KundeEntitiesActions.deleteKundeSuccess, (state, {kundeId}) => {
    return kundeEntitiesAdapter.removeOne(
      kundeId,
      {
        ...state,
        totalElements: state.totalElements - 1,
        deleteActionSuccessful: true,
      },
    );
  }),

  on(KundeEntitiesActions.deleteKundeFailure, (state) => ({
    ...state,
    deleteActionSuccessful: false,
  })),

  /**
   * Beim Schließen des Create- / Update-Dialogs wird der Status zurückgesetzt.
   */
  on(KundeDialogActions.close, (state) => ({
    ...state,
    createSaveActionSuccessful: false,
  })),

  /**
   * Beim Schließen des Delete-Dialogs wird der Status zurückgesetzt.
   */
  on(DeleteKundeDialogActions.close, (state) => ({
    ...state,
    deleteActionSuccessful: false,
  })),
);
