import {KundeLeistungsempfaengerState} from '../states/kunde-leistungsempfaenger.state';
import {createReducer, on} from '@ngrx/store';
import {KundeLeistungsempfaengerActions} from '../actions/kunde-leistungsempfaenger.actions';
import {FakturierungsbelegFormActions} from '../actions/fakturierungsbeleg-form.actions';


export const initialKundeLeistungsempfaengerState: KundeLeistungsempfaengerState = {
  displayedIds: [],
  pageableDto: {
    pageSize: 15,
    offset: 0,
    pageNumber: 0,
  },
  filterableDto: {
    filter: [],
  }
};

export const kundeLeistungsempfaengerReducer = createReducer(
  initialKundeLeistungsempfaengerState,

  on(
    KundeLeistungsempfaengerActions.setDisplayedIds,
    (state, {kundeDtos}) => ({
      ...state,
      displayedIds: kundeDtos.map(kundeDto => kundeDto.id!),
    }),
  ),

  on(
    KundeLeistungsempfaengerActions.clearDisplayedKunden,
    (state) => ({
      ...state,
      displayedIds: [],
    }),
  ),

  on(
    KundeLeistungsempfaengerActions.updateTextFilter,
    (state, {filter}) => ({
      ...state,
      filterableDto: {
        ...state.filterableDto,
        filter,
      }
    }),
  ),

  on(
    KundeLeistungsempfaengerActions.loadMoreOptions,
    (state) => ({
      ...state,
      pageableDto: {
        ...state.pageableDto,
        offset: state.pageableDto.offset! + state.pageableDto.pageSize!,
      }
    }),
  ),

  on(FakturierungsbelegFormActions.setLeistungsempfaenger, (state, {kundeId}) => {
    let updatedDisplayedIds = [...state.displayedIds];
    if (!updatedDisplayedIds.includes(kundeId!)) {
      updatedDisplayedIds = [
        ...updatedDisplayedIds,
        kundeId!,
      ];
    }

    return {
      ...state,
      displayedIds: updatedDisplayedIds,
    };
  }),
);
