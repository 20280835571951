import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/states/app.state';
import {NGXLogger} from 'ngx-logger';
import {ProduktDialogActions} from '../../../store/actions/produkt-dialog.actions';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {InhaberEntitiesSelectors, OptionComponent} from '@adnova/jf-ng-components';
import {Subject, take} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ProduktEntitiesActions} from '../../../store/actions/produkt-entities.actions';
import {ProduktDTO} from '../../../openapi/fakturierung-openapi';
import {FormInput} from '../../components/form/form-input.interface';
import {CreateProduktFormControls} from './produkt-form-controls.interface';
import {ProduktEntitiesSelectors} from '../../../store/selectors/produkt-entities.selectors';
import {ProduktDialogSelectors} from '../../../store/selectors/produkt-dialog.selectors';


@Component({
  selector: 'app-create-produkt-dialog',
  templateUrl: './produkt-dialog.component.html',
  styleUrls: ['./produkt-dialog.component.scss']
})
export class ProduktDialogComponent implements OnInit, OnDestroy {

  private readonly unsubscribe$ = new Subject<void>();

  private _inhaberId?: string;
  private _addToInvoice: boolean = false;
  private _einheiten: OptionComponent[] = [];
  private _umsatzsteuersaetze: OptionComponent[] = [];
  private _berechnungsarten: OptionComponent[] = [];

  get berechnungsarten(): OptionComponent[] {
    return this._berechnungsarten || [];
  }

  get einheiten(): OptionComponent[] {
    return this._einheiten || [];
  }

  get umsatzsteuersaetze(): OptionComponent[] {
    return this._umsatzsteuersaetze || [];
  }

  public formControls: CreateProduktFormControls = {
    produktBezeichnung: new FormControl('', [Validators.required]),
    einheit: new FormControl({}, []),
    betragssumme: new FormControl(null, []),
    berechnungsart: new FormControl({}, [Validators.required]),
    ustProzentsatz: new FormControl({}, [Validators.required]),
    produktbeschreibung: new FormControl('', []),
    produktnummer: new FormControl(null, [Validators.required]),
  };

  protected formInputs: FormInput = {
    title: 'Neues Produkt anlegen',
    cancelButtonLabel: 'Abbrechen',
  };

  protected produktDto?: ProduktDTO;

  protected formGroup = new FormGroup(this.formControls);

  protected primaryButtonLabel = 'Produkt anlegen';

  constructor(
    private store: Store<AppState>,
    private logger: NGXLogger,
  ) {
  }

  ngOnInit() {
    // INFO: Inhaber ID
    this.store.select(InhaberEntitiesSelectors.currentInhaber).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(inhaber => {
      if (!inhaber) return;
      this._inhaberId = inhaber?.id;
    });

    // INFO: Produkt
    this.store.select(ProduktDialogSelectors.selectedProduktId).pipe(
      take(1),
    ).subscribe(produktId => {
      if (!produktId) return;

      this.store.select(ProduktEntitiesSelectors.produktById(produktId)).pipe(
        take(1),
      ).subscribe(produktDto => {
        this.produktDto = produktDto;

        this.formInputs.title = 'Produkt bearbeiten';
        this.primaryButtonLabel = 'Speichern';

        if (produktDto) {
          // INFO: Pflichtfelder
          this.formControls.produktBezeichnung.setValue(produktDto.bezeichnung);
          this.formControls.produktnummer.setValue(produktDto.nummer);

          // FIXME: Mit Team ARC klären, wie betragssumme angewendet werden.
          // INFO: Optionale Felder
          // if (produktDto.betragssumme) {
          //   this.formControls.betragssumme.setValue(produktDto.betragssumme);
          // }

          if (produktDto.beschreibung) {
            this.formControls.produktbeschreibung.setValue(produktDto.beschreibung);
          }
        } else {
          this.logger.error('produkt not found by id at product dialog.', produktId);
        }
      });
    });

    // FIXME: Mit Team ARC klären, wie Einheiten angewendet werden.
    // // INFO: Einheiten
    // this.store.select(ProduktEntitiesSelectors.einheiten).pipe(
    //   takeUntil(this.unsubscribe$),
    // ).subscribe(einheiten => {
    //   if (!einheiten) return;
    //
    //   for (const einheit of einheiten) {
    //     const option = new OptionComponent();
    //     option.id = einheit.einheitId;
    //     option.label = einheit.bezeichnung || '';
    //     option.isSelected = this.produktDto ? this.produktDto.einheitId === einheit.einheitId : false;
    //
    //     this._einheiten.push(option);
    //   }
    // });

    // FIXME: Mit Team ARC klären, wie umsatzsteuersaetze angewendet werden.
    // INFO: Umsatzsteuersätze
    // this.store.select(ProduktEntitiesSelectors.umsatzsteuersaetze).pipe(
    //   takeUntil(this.unsubscribe$),
    // ).subscribe(umsatzsteuersaetze => {
    //   if (!umsatzsteuersaetze) return;
    //
    //   for (const ust of umsatzsteuersaetze) {
    //     const label = Math.floor(ust.prozentsatz! * 100);
    //
    //     const option = new OptionComponent();
    //     option.id = ust.id;
    //     option.label = label + ' %';
    //     option.isSelected = this.produktDto ? this.produktDto.ustProzentsatzId === ust.id : false;
    //
    //     this._umsatzsteuersaetze.push(option);
    //   }
    // });

    // FIXME: Mit Team ARC klären, wie Berechnungsarten angewendet werden.
    // INFO: Berechnungsarten
    // this.store.select(ProduktEntitiesSelectors.berechnungsarten).pipe(
    //   takeUntil(this.unsubscribe$),
    // ).subscribe(berechnungsarten => {
    //   if (!berechnungsarten) return;
    //
    //   for (const berechnungsart of berechnungsarten) {
    //     const option = new OptionComponent();
    //     option.id = berechnungsart.id;
    //     option.label = berechnungsart.berechnungsart || '';
    //     option.isSelected = this.produktDto ? this.produktDto.berechnungsartId === berechnungsart.id : false;
    //
    //     this._berechnungsarten.push(option);
    //   }
    // });

    // INFO: Next Produktnummer
    this.store.select(ProduktEntitiesSelectors.nextProduktnummer).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(nextProduktnummer => {
      if (!this.produktDto) {
        this.formControls.produktnummer.setValue(nextProduktnummer);
      }
    });

    this.store.select(ProduktDialogSelectors.addToInvoice).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(addToInvoice => {
      this._addToInvoice = addToInvoice ?? false;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  protected saveActionClick(): void {
    if (this.produktDto) {

      // FIXME
      //   // INFO: Ist ein Produkt vorhanden, dann handelt es sich um ein Update.
      //   this.store.dispatch(ProduktEntitiesActions.updateProdukt({
      //     betriebId: this._inhaberId!,
      //     produktId: this.produktDto.id!,
      //     requestDto: this.createUpdateProduktRequestDTO()
      //   }));
      //
      // } else {
      //   // INFO: Ist kein Produkt vorhanden, dann handelt es sich um ein Create.
      //
      //   this.store.dispatch(ProduktEntitiesActions.createProdukt({
      //     betriebId: this._inhaberId!,
      //     requestDto: this.createCreateProduktRequestDTO(),
      //     addToInvoice: this._addToInvoice,
      //   }));
    }

    /*
     * FIXME
     * Das Schließen des Dialogs darf nur passieren,
     * wenn das Erstellen oder Aktualisieren des Produkts Erfolgreich gewesen ist.
     * Dies können wir aber erst implementieren, wenn das Backend verfügbar ist.
     */
    this.closeDialogClick();
  }

  protected closeDialogClick(): void {
    this.store.dispatch(ProduktDialogActions.close());
  }

  // FIXME: ProduktRequestDTO

  // /**
  //  * Erstellt ein CreateProduktRequestDTO aus den Formularwerten.
  //  * Aktuell ist das CreateProduktRequestDTO sowie das UpdateProduktRequestDTO identisch.
  //  * Das ist aktuell OK und kann sich zukünftig ändern.
  //  *
  //  * @protected
  //  */
  // protected createCreateProduktRequestDTO(): CreateProduktRequestDTO {
  //   return {
  //     produktbezeichnung: this.formControls.produktBezeichnung.value || '',
  //     einheitId: this.formControls.einheit.value?.selectedOptionValueIds?.[0] || '',
  //     betragssumme: this.formControls.betragssumme.value || 0,
  //     berechnungsartId: this.formControls.berechnungsart.value?.selectedOptionValueIds?.[0] || '',
  //     ustProzentsatzId: this.formControls.ustProzentsatz.value?.selectedOptionValueIds?.[0] || '',
  //     produktbeschreibung: this.formControls.produktbeschreibung.value || '',
  //     produktnummer: this.formControls.produktnummer.value || 0,
  //   };
  // }
  //
  // /**
  //  * Erstellt ein UpdateProduktRequestDTO aus den Formularwerten.
  //  * Aktuell ist das CreateProduktRequestDTO sowie das UpdateProduktRequestDTO identisch.
  //  * Das ist aktuell OK und kann sich zukünftig ändern.
  //  *
  //  * @protected
  //  */
  // protected createUpdateProduktRequestDTO(): UpdateProduktRequestDTO {
  //   return {
  //     produktbezeichnung: this.formControls.produktBezeichnung.value || '',
  //     einheitId: this.formControls.einheit.value?.selectedOptionValueIds?.[0] || '',
  //     betragssumme: this.formControls.betragssumme.value || 0,
  //     berechnungsartId: this.formControls.berechnungsart.value?.selectedOptionValueIds?.[0] || '',
  //     ustProzentsatzId: this.formControls.ustProzentsatz.value?.selectedOptionValueIds?.[0] || '',
  //     produktbeschreibung: this.formControls.produktbeschreibung.value || '',
  //     produktnummer: this.formControls.produktnummer.value || 0,
  //   };
  // };
}
