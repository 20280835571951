import {Component, DestroyRef, inject, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  FormFieldNumberComponent,
  FormFieldSelectComponent,
  FormFieldTextComponent,
  OptionComponent
} from '@adnova/jf-ng-components';
import {KundePrivatpersonFormControls} from './kunde-privatperson-form.controls';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatDividerModule} from '@angular/material/divider';
import {KundenFormType} from '../../../dialogs/kunde-dialog/kunden-form.type';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';


@Component({
  selector: 'app-kunde-privatperson-form',
  standalone: true,
  imports: [CommonModule, FormFieldSelectComponent, FormFieldTextComponent, FormFieldNumberComponent, FormsModule, ReactiveFormsModule, MatDividerModule],
  templateUrl: './kunde-privatperson-form.component.html',
  styleUrls: ['./kunde-privatperson-form.component.scss']
})
export class KundePrivatpersonFormComponent implements OnInit {

  private destroyRef = inject(DestroyRef);

  @Input() public formType?: KundenFormType;
  @Input() public formControls?: KundePrivatpersonFormControls;
  @Input() public laenderOptions?: OptionComponent[];
  @Input() public anredenOptions?: OptionComponent[];
  private _kundennummerVorgeschlagen = '';

  get kundennummerVorgeschlagen(): string {
    return this._kundennummerVorgeschlagen;
  }

  set kundennummerVorgeschlagen(value: string) {
    this._kundennummerVorgeschlagen = value;
  }

  ngOnInit() {
    this.formControls?.kundennummer.statusChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      this.kundennummerVorgeschlagen =
        this.formType === KundenFormType.CREATE && this.formControls?.kundennummer.pristine ? 'Vorgeschlagene Kundennummer' : '';
    });
  }

}
