import {AddPositionState} from '../states/add-position.state';
import {produktEntitiesMock} from '../../mocks/data/produkt-entities.mock';
import {createReducer, on} from '@ngrx/store';
import {AddPositionActions} from '../actions/add-position.actions';


export const initialAddPositionState: AddPositionState = {
  displayedIds: produktEntitiesMock.ids,
  pageableDto: {
    pageSize: 15,
    offset: 0,
    pageNumber: 0,
  },
  filterableDto: {
    filter: [],
  },
};

export const addPositionReducer = createReducer(
  initialAddPositionState,

  on(
    AddPositionActions.setDisplayedIds,
    (state, {produktDtos}) => ({
      ...state,
      displayedIds: produktDtos.map(produktDto => produktDto.id!),
    }),
  ),

  on(
    AddPositionActions.clearDisplayedProdukte,
    (state) => ({
      ...state,
      displayedIds: [],
    }),
  ),

  on(
    AddPositionActions.updateFilter,
    (state, {filter}) => ({
      ...state,
      filterableDto: {
        ...state.filterableDto,
        filter,
      }
    }),
  ),

  on(
    AddPositionActions.loadMoreOptions,
    (state) => ({
      ...state,
      pageableDto: {
        ...state.pageableDto,
        offset: state.pageableDto.offset! + state.pageableDto.pageSize!,
      }
    }),
  ),
);
