import {Injectable} from '@angular/core';
import {KundeEntitiesEffects} from './kunde-entities.effects';
import {KundeLeistungsempfaengerActions} from '../actions/kunde-leistungsempfaenger.actions';


@Injectable()
export class KundeLeistungsempfaengerEffects {

  constructor(
    private kundeEntitiesEffects: KundeEntitiesEffects,
  ) {
  }

  readonly readKunden$ = this.kundeEntitiesEffects.readKunden$(
    KundeLeistungsempfaengerActions.readKunden,
    KundeLeistungsempfaengerActions.setDisplayedIds,
    KundeLeistungsempfaengerActions.clearDisplayedKunden,
  );

}
