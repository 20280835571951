import {FakturierungsbelegEntitiesState} from '../states/fakturierungsbeleg-entities.state';
import {fakturierungsbelegEntitiesAdapter} from '../adapters/fakturierungsbeleg-entities.adapter';
import {createReducer, on} from '@ngrx/store';
import {FakturierungsbelegEntitiesActions} from '../actions/fakturierungsbeleg-entities.actions';
import {positionenMock} from '../../mocks/data/positionen.mock';


export const initialFakturierungsbelegEntitiesState: FakturierungsbelegEntitiesState = fakturierungsbelegEntitiesAdapter.getInitialState({
  isLoading: false,
  entities: {
    [1]: {
      id: '1',
      status: 'bezahlt',
      zahlungszielDatum: '01.01.2021',
      belegnummer: 'R-1-2024',
      kundenbezeichnung: 'Max Mustermann',
      kundennummer: 10000,
      datum: '12.10.2024',
      nettoBetragssumme: '100.00',
      bruttoBetragssumme: '119.00',
      nachlaufzeilen: [
        'Wir danken für Ihren Auftrag und freuen uns auf eine gute Zusammenarbeit.',
        'Bitte überweisen Sie den Betrag innerhalb von 14 Tagen auf unser Konto.',
      ],
      betriebId: '3982cf7f-4c03-496d-9099-a0fe11792be4',
      positionen: positionenMock,
      absenderId: '1',
      vorlaufzeile: 'Danke, danke, danke. Wir erlauben uns, die nachfolgenden Dinge zu berechnen:',
    }, [2]: {
      id: '2',
      status: 'bezahlt',
      zahlungszielDatum: '01.01.2021',
      belegnummer: 'R-2-2024',
      kundenbezeichnung: 'Tim Tom',
      kundennummer: 10000,
      datum: '01.01.2021',
      nettoBetragssumme: '100.00',
      bruttoBetragssumme: '119.00',
      betriebId: '3982cf7f-4c03-496d-9099-a0fe11792be4',
      absenderId: '29',
      vorlaufzeile: 'Dies ist ein Begrüßungstext',
    }, [3]: {
      id: '3',
      status: 'bezahlt',
      zahlungszielDatum: '01.01.2021',
      belegnummer: 'R-3-2024',
      kundenbezeichnung: 'Bob Blob',
      kundennummer: 10000,
      datum: '22.05.2023',
      nettoBetragssumme: '100.00',
      bruttoBetragssumme: '119.00',
      positionen: positionenMock,
      betriebId: '3982cf7f-4c03-496d-9099-a0fe11792be4',
      absenderId: '10',
      vorlaufzeile: 'Sieh zu, dass du bezahlst Junge!',
    },
  },
  ids: [
    1,
    2,
    3,
  ],
  count: 3,
});

export const fakturierungsbelegEntitiesReducer = createReducer(
  initialFakturierungsbelegEntitiesState,

  on(
    FakturierungsbelegEntitiesActions.deleteFakturierungsbelegSuccess,
    (state, {fakturierungsbelegId}) => {
      return fakturierungsbelegEntitiesAdapter.removeOne(fakturierungsbelegId, {
        ...state,
      });
    }
  ),
);
