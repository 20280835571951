import {Component, inject} from '@angular/core';
import {BelegDTO} from '../../../../../openapi/fakturierung-openapi';
import {ELEMENT_DATA} from '../../../../components/table/table-wrapper/table/table.component';

@Component({
  standalone: true,
  selector: 'app-rechnungsdatum.cell',
  templateUrl: './rechnungsdatum.cell.component.html',
})
export class RechnungsdatumCellComponent {

  protected data?: BelegDTO = inject(ELEMENT_DATA);

}
