import {Component, DestroyRef, inject, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {KundeGeschaeftskundeFormControls} from './kunde-geschaeftskunde-form.interface';
import {
  FormFieldNumberComponent,
  FormFieldSelectComponent,
  FormFieldTextComponent,
  OptionComponent
} from '@adnova/jf-ng-components';
import {MatDividerModule} from '@angular/material/divider';
import {ReactiveFormsModule} from '@angular/forms';
import {KundenFormType} from '../../../dialogs/kunde-dialog/kunden-form.type';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';


@Component({
  selector: 'app-kunde-geschaeftskunde-form',
  standalone: true,
  imports: [CommonModule, FormFieldTextComponent, FormFieldNumberComponent, FormFieldSelectComponent, MatDividerModule, ReactiveFormsModule],
  templateUrl: './kunde-geschaeftskunde-form.component.html',
  styleUrls: ['./kunde-geschaeftskunde-form.component.scss']
})
export class KundeGeschaeftskundeFormComponent implements OnInit {

  private destroyRef = inject(DestroyRef);

  @Input() public formType?: KundenFormType;
  @Input() public formControls?: KundeGeschaeftskundeFormControls;
  @Input() public laenderOptions?: OptionComponent[];
  @Input() public anredenOptions?: OptionComponent[];

  private _kundennummerVorgeschlagen = '';

  get kundennummerVorgeschlagen(): string {
    return this._kundennummerVorgeschlagen;
  }

  set kundennummerVorgeschlagen(value: string) {
    this._kundennummerVorgeschlagen = value;
  }

  ngOnInit() {
    this.formControls?.kundennummer.statusChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      this.kundennummerVorgeschlagen = this.formType === KundenFormType.CREATE && this.formControls?.kundennummer.pristine ? 'Vorgeschlagene Kundennummer' : '';
    });
  }
}
