import {TableSettings} from '../../interfaces/table-setting.interface';
import {FilterableDTO, PageableDTO} from '../../openapi/fakturierung-openapi';


export enum AbsenderColumn {
  NameVorname = 'nameVorname',
  Firma = 'firma',
  Adresse = 'adresse',
  Actions = 'actions',
}

export interface KundeTableState {
  displayedIds: string[];
  tableSettings: TableSettings;
  pageableDto: PageableDTO;
  filterableDto: FilterableDTO;
  totalPages: number
}
