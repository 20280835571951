import {createReducer, on} from '@ngrx/store';
import {KundeTableActions} from '../actions/kunde-table.actions';
import {KundeEntitiesActions} from '../actions/kunde-entities.actions';
import {AbsenderColumn, KundeTableState} from '../states/kunde-table.state';


export const initialKundeTableState: KundeTableState = {
  displayedIds: [],
  tableSettings: {
    _key: 'absender-table-settings-2024-06-18',
    _columns: [
      {
        id: AbsenderColumn.NameVorname,
        name: 'Nachname, Vorname',
        isActive: true,
        width: 15,
        overflow: 'ellipsis',
      }, {
        id: AbsenderColumn.Firma,
        name: 'Firma',
        isActive: true,
        width: 15,
        overflow: 'break',
      }, {
        id: AbsenderColumn.Adresse,
        name: 'Ort',
        isActive: true,
        width: 10,
        overflow: 'break',
      }, {
        id: AbsenderColumn.Actions,
        name: '',
        isActive: true,
        sortable: false,
        lastCol: true,
      },
    ],
    _sortDirs: [],
    _sortParams: [],
  },
  pageableDto: {
    pageSize: 50,
    offset: 0,
    pageNumber: 0,
  },
  filterableDto: {
    filter: [''],
  },
  totalPages: 0,
};

export const kundeTableReducer = createReducer(
  initialKundeTableState,

  on(KundeTableActions.setDisplayedIds, (state, {kundeDtos}) => ({
      ...state,
      displayedIds: kundeDtos.map(kundeDto => kundeDto.id),
    }),
  ),

  on(KundeTableActions.clearDisplayedKunden, (state) => ({
      ...state,
      displayedIds: [],
    }),
  ),

  on(KundeTableActions.changeTableSettings, (state, action) => ({
      ...state,
      tableSettings: action.tableSettings,
    })
  ),

  on(KundeTableActions.changePage, (state, action) => ({
      ...state,
      pageableDto: {
        ...state.pageableDto,
        ...action.pageableDto,
      },
    })
  ),

  on(KundeTableActions.updateSearchValue, (state, {filter}) => ({
      ...state,
      filterableDto: {
        filter,
      }
    })
  ),

  on(KundeEntitiesActions.createKundeSuccess, (state, {kundeDto}) => ({
    ...state,
    displayedIds: [...state.displayedIds, kundeDto.id],
  })),

  on(KundeEntitiesActions.countKundenPagesSuccess, (state, {totalPages}) => ({
    ...state,
    totalPages,
  })),
);
