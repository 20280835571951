import {Component, OnInit} from '@angular/core';
import {AppState} from '../../../store/states/app.state';
import {Store} from '@ngrx/store';
import {ProduktEntitiesSelectors} from '../../../store/selectors/produkt-entities.selectors';
import {
  DeleteProduktDialogSelectors
} from '../../../store/selectors/delete-produkt-dialog.selectors';
import {take} from 'rxjs';
import {ProduktDTO} from '../../../openapi/fakturierung-openapi';
import {DeleteProduktDialogActions} from '../../../store/actions/delete-produkt-dialog.actions';
import {NGXLogger} from 'ngx-logger';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
  selector: 'app-delete-produkt-dialog',
  templateUrl: './delete-produkt-dialog.component.html',
  styleUrls: ['./delete-produkt-dialog.component.scss']
})
export class DeleteProduktDialogComponent implements OnInit {

  protected produktDto?: ProduktDTO;
  protected prozentsatz: string = '19';

  constructor(
    private store: Store<AppState>,
    private logger: NGXLogger,
    private snackbar: MatSnackBar,
  ) {
  }

  ngOnInit() {
    this.store.select(DeleteProduktDialogSelectors.deleteData).pipe(
      take(1),
    ).subscribe(data => {

      if (data.produktId) {
        this.store.select(ProduktEntitiesSelectors.produktById(
          data.produktId,
        )).pipe(
          take(1),
        ).subscribe(produktDto => {
          this.produktDto = produktDto;
        });
      } else {
        this.logger.warn('delete dialog produktId is not set');
      }
    });

    // FIXME: Mit Team ARC klären, wie die Umsatzsteuersätze geladen werden.
    // this.store.select(ProduktEntitiesSelectors.umsatzsteuersaetze).pipe(
    //   take(1),
    // ).subscribe(ustProzentsatzDtos => {
    //   this.prozentsatz = ustProzentsatzDtos.find(ust => {
    //     return ust.id === this.produktDto?.ustProzentsatzId;
    //   })?.prozentsatz?.toString() || '';
    // });
  }

  protected deleteProduktClick(): void {
    this.snackbar.open('Produkt löschen wurde geklickt', undefined, {
      duration: 1000,
    });

    this.store.dispatch(DeleteProduktDialogActions.close());
  }

  protected closeDialogClick(): void {
    this.store.dispatch(DeleteProduktDialogActions.close());
  }
}
