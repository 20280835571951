import {createAction, props} from '@ngrx/store';
import {
  ProduktDTO, ProduktRequestDTO, ProduktResponseDTO,
} from '../../openapi/fakturierung-openapi';
import {MappedHttpErrorResponse} from '@adnova/jf-ng-components';


export class ProduktEntitiesActions {

  public static readProdukteSuccess = createAction(
    '[Produkt Entities] Read list of produkte successfully.',
    props<{
      produktDtos: ProduktDTO[]
    }>(),
  );

  public static readProdukteFailed = createAction(
    '[Produkt Entities] Read list of produkte failed.',
    props<{
      error: MappedHttpErrorResponse,
    }>(),
  );

  public static countProdukteElementsSuccess = createAction(
    '[Produkt Entities] Count list of produkte elements successfully.',
    props<{
      totalElements: number,
    }>(),
  );

  public static countProduktePagesSuccess = createAction(
    '[Produkt Effects] Count list of produkte pages successfully.',
    props<{
      totalPages: number,
    }>(),
  );

  public static loadProduktByIdIfAbsent = createAction(
    '[Produkt Entities] Load produkt by ID if absent.',
    props<{
      betriebId: string;
      produktId: string;
    }>(),
  );

  public static getProduktById = createAction(
    '[Produkt Effects] Get produkt by ID.',
    props<{
      betriebId: string;
      produktId: string;
    }>(),
  );

  public static getProduktByIdSuccess = createAction(
    '[Produkt Entities] Get produkt successfully.',
    props<{
      produktDto: ProduktDTO;
    }>(),
  );

  public static getProduktByIdFailure = createAction(
    '[Produkt Entities] Get produkt failed.',
    props<{
      error: any;
    }>(),
  );

  public static createProdukt = createAction(
    '[Create Produkt Dialog] Create produkt.',
    props<{
      betriebId: string,
      requestDto: ProduktRequestDTO,
      addToInvoice?: boolean,
    }>(),
  );

  public static updateProdukt = createAction(
    '[Update Produkt Dialog] Update produkt.',
    props<{
      betriebId: string,
      produktId: string,
      requestDto: ProduktRequestDTO,
    }>(),
  );

  public static updateProduktSuccess = createAction(
    '[Produkt Entities] Update produkt successfully.',
    props<{ responseDto: ProduktResponseDTO }>(),
  );

  public static updateProduktFailure = createAction(
    '[Produkt Entities] Update produkt failed.',
    props<{ error: any }>(),
  );

  public static createProduktSuccess = createAction(
    '[Produkt Entities] Create produkt successfully.',
  );

  public static createProduktFailure = createAction(
    '[Produkt Entities] Create produkt failed.',
    props<{ error: any }>(),
  );

  public static readNextProduktnummer = createAction(
    '[Produkt Entities] Read next Produktnummer.',
    props<{
      betriebId: string;
    }>(),
  );

  public static readNextProduktnummerSuccess = createAction(
    '[Produkt Entities] Read next Produktnummer.',
    props<{
      produktnummer: number;
    }>(),
  );

  public static readNextProduktnummerFailure = createAction(
    '[Produkt Entities] Read next Produktnummer.',
    props<{
      error: any;
    }>(),
  );
}
