import {createReducer, on} from '@ngrx/store';
import {ProduktDialogState} from '../states/produkt-dialog.state';
import {ProduktDialogActions} from '../actions/produkt-dialog.actions';


export const initialProduktDialogState: ProduktDialogState = {
  isOpen: false,
  produktId: undefined,
  addToInvoice: undefined,
};

export const produktDialogReducer = createReducer(
  initialProduktDialogState,

  on(
    ProduktDialogActions.open,
    (state, action) => ({
      ...state,
      isOpen: true,
      produktId: action.produktId,
      addToInvoice: action.addToInvoice ?? undefined,
    })
  ),

  on(
    ProduktDialogActions.close,
    () => ({
      ...initialProduktDialogState,
    })
  ),
);
