import {createAction, props} from '@ngrx/store';


export class KundeDialogActions {

  public static open = createAction(
    '[Kunde Dialog] Open kontakt dialog.',
    props<{
      kundeId: string | undefined,
      addToInvoice?: boolean,
    }>(),
  );

  public static close = createAction(
    '[Kunde Dialog] Close kontakt dialog.',
  );

  public static readLaender = createAction(
    '[Kunde Dialog] Read laender.',
  );

  public static readNextKundennummer = createAction(
    '[Kunde Entities] Read next kundennummer.',
    props<{ betriebId: string }>(),
  );

  public static readNextKundennummerSuccess = createAction(
    '[Kunde Entities] Read next kundennummer successfully.',
    props<{ nextKundennummer: number }>(),
  );

  public static readNextKundennummerFailure = createAction(
    '[Kunde Entities] Read next kundennummer failed.',
    props<{ error: any }>(),
  );

  // FIXME: Schlüsseldaten-Service anbinden
  // public static readLaenderSuccess = createAction(
  //   '[Kunde Dialog] Read laender success.',
  //   props<{ laenderDtos: LandDTO[] }>(),
  // );
  //
  // public static readLaenderFailure = createAction(
  //   '[Kunde Dialog] Read laender failure.',
  //   props<{ error: any }>(),
  // );
}
