import {createAction, props} from '@ngrx/store';
import {FakturierungsbelegFormComponentMode} from '../../types/fakturierungsbeleg-form-component-mode.type';
import {BelegDTO, ProduktDTO} from '../../openapi/fakturierung-openapi';
import {PositionAnzahlMode} from '../../interfaces/position-anzahl-modes.interface';
import {PositionAnzahl} from '../../interfaces/position-anzahl.interface';
import {
  PositionBetragssummeMode,
} from '../../interfaces/position-betragssumme-mode.interface';
import {PositionBetragssumme} from '../../interfaces/position-betragssumme.interface';
import {NachlaufzeileInput} from '../../modules/components/fakturierungsbelege-form/nachlaufzeilen/nachlaufzeile-list.component';


export class FakturierungsbelegFormActions {

  public static reset = createAction(
    '[Fakturierungsbeleg Form] Reset this context.',
  );

  public static updateFakturierungsbelegFormDto = createAction(
    '[Fakturierungsbeleg Form] Updated the selected fakturierungsbeleg data.',
    props<{
      fakturierungsbelegDto: BelegDTO,
    }>(),
  );

  public static changeLeistungsempfaengerMode = createAction(
    '[Fakturierungsbeleg Form] Change Leistungsempfaenger Mode.',
    props<{
      mode: FakturierungsbelegFormComponentMode,
    }>(),
  );

  public static setLeistungsempfaenger = createAction(
    '[Fakturierungsbeleg Form] Set Leistungsempfänger',
    props<{
      kundeId?: string,
    }>(),
  );

  public static changeVorlaufzeileMode = createAction(
    '[Fakturierungsbeleg Form] Change Vorlaufzeile Mode.',
    props<{
      mode: FakturierungsbelegFormComponentMode,
    }>(),
  );

  public static setVorlaufzeileText = createAction(
    '[Fakturierungsbeleg Form] Set Vorlaufzeile Text.',
    props<{
      text: string,
    }>(),
  );

  public static addNachlaufzeile = createAction(
    '[Fakturierungsbeleg Form] Add Nachlaufzeile',
    props<{
      nachlaufzeile: NachlaufzeileInput,
    }>(),
  );

  public static setNachlaufzeilen = createAction(
    '[Fakturierungsbeleg Form] Add Nachlaufzeilen',
    props<{
      nachlaufzeilen: NachlaufzeileInput[],
    }>(),
  );

  public static removeNachlaufzeile = createAction(
    '[Fakturierungsbeleg Form] Remove Nachlaufzeile',
    props<{
      nachlaufzeileId: string,
    }>(),
  );

  public static toggleNachlaufzeilenMode = createAction(
    '[Fakturierungsbeleg Form] Toggle Nachlaufzeilen Mode',
    props<{
      nachlaufzeileId: string,
    }>(),
  );

  public static removePositionByNumber = createAction(
    '[Fakturierungsbeleg Form] Remove Position',
    props<{
      number: number,
    }>(),
  );

  public static changePositionAnzahlMode = createAction(
    '[Fakturierungsbeleg Form] Change Position Anzahl Mode.',
    props<{
      positionAnzahlMode: PositionAnzahlMode,
    }>(),
  );

  public static setPositionAnzahl = createAction(
    '[Fakturierungsbeleg Form] Set Position Anzahl.',
    props<{
      positionAnzahl: PositionAnzahl,
    }>(),
  );

  public static changePositionBetragssummeMode = createAction(
    '[Fakturierungsbeleg Form] Change Position Betragssumme Mode.',
    props<{
      positionBetragssummeMode: PositionBetragssummeMode,
    }>(),
  );

  public static setPositionBetragssummme = createAction(
    '[Fakturierungsbeleg Form] Set Position Betragssumme.',
    props<{
      positionBetragssumme: PositionBetragssumme,
    }>(),
  );

  public static changeAddProduktMode = createAction(
    '[Fakturierungsbeleg Form] Change Add-Produkt Mode.',
    props<{
      mode: FakturierungsbelegFormComponentMode,
    }>(),
  );

  public static addProdukt = createAction(
    '[Fakturierungsbeleg Form] Add Produkt.',
    props<{
      produktDto: ProduktDTO,
    }>(),
  );

  public static changeRechnungsdatumMode = createAction(
    '[Fakturierungsbeleg Form] Change Rechnungsdatum Mode.',
    props<{
      mode: FakturierungsbelegFormComponentMode,
    }>(),
  );

  public static setRechnungsdatum = createAction(
    '[Fakturierungsbeleg Form] Set Rechnungsdatum.',
    props<{
      rechnungsdatum: string,
    }>(),
  );

  public static getLogo = createAction(
    '[Fakturierungsbeleg Form] Get logo.',
    props<{ betriebId: string }>()
  );

  public static getLogoSuccess = createAction(
    '[Fakturierungsbeleg Form] Get logo successfully.',
    props<{ logoUrl: string | null }>()
  );

  public static getLogoFailure = createAction(
    '[Fakturierungsbeleg Form] Get logo failed.',
    props<{ error: any }>(),
  );

  public static saveLogo = createAction(
    '[Fakturierungsbeleg Form] Save logo.',
    props<{
      betriebId: string,
      logoUrl: string,
    }>(),
  );

  public static saveLogoSuccess = createAction(
    '[Fakturierungsbeleg Form] Save logo successfully.',
    props<{ logoUrl: string }>()
  );

  public static saveLogoFailure = createAction(
    '[Fakturierungsbeleg Form] Save logo failed.',
    props<{ error: any }>()
  );

  public static deleteLogo = createAction(
    '[Fakturierungsbeleg Form] Delete logo.',
    props<{ betriebId: string }>()
  );

  public static deleteLogoSuccess = createAction(
    '[Fakturierungsbeleg Form] Delete logo successfully.',
  );

  public static deleteLogoFailure = createAction(
    '[Fakturierungsbeleg Form] Delete logo failed.',
    props<{ error: any }>()
  );

  public static releaseLogoFromMemory = createAction(
    '[Fakturierungsbeleg Form] Release logo from browser memory.',
    props<{ logoUrl?: string }>()
  );
}
