<mat-dialog-content>
  <app-form
    [formInput]="formInputs"
    (cancelClicked)="closeDialogClick()"
  >
    <div *ngIf="produktDto" class="header-content">
      Änderungen an bestehenden Produkten aktualisieren automatisch die zugehörigen<br>
      Rechnungsentwürfe und überschreiben das bisherige Produkt.
    </div>
    <div
      class="body-content">
      <app-produkt-form
        [formControls]="formControls"
        [einheitenOptions]="einheiten"
        [berechnungsarten]="berechnungsarten"
        [umsatzsteuersaetze]="umsatzsteuersaetze"
      ></app-produkt-form>
    </div>

    <div
      class="footer-content">
      <jf-button
        [variant]="'primary'"
        (click)="saveActionClick()"
        [disabled]="!formGroup.valid || formGroup.pristine"
      >
        {{ primaryButtonLabel }}
      </jf-button>
    </div>
  </app-form>
</mat-dialog-content>
