import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PageableDTO} from '../../../openapi/fakturierung-openapi';


@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {

  private _totalElements: number = 0;
  private _totalPages: number = 0;
  private _offset: number = 0;
  private _pageSize: number = 0;
  private _pageNumber: number = 0;

  @Input()
  set totalElements(totalElements: number) {
    this._totalElements = totalElements;
  }

  @Input()
  set offset(offset: number) {
    this._offset = offset;
  }

  @Input()
  set pageSize(limit: number) {
    this._pageSize = limit;
  }

  @Input()
  set pageNumber(pageNumber: number) {
    this._pageNumber = pageNumber;
  }

  @Input()
  set totalPages(totalPages: number) {
    this._totalPages = totalPages;
  }

  @Output()
  pageableChange = new EventEmitter<PageableDTO>();

  get totalElements(): number {
    return this._totalElements;
  }

  get offsetStart(): number {
    return this._offset + 1;
  }

  get offsetEnd(): number {
    return this._offset + this._pageSize;
  }

  paginateFirst(): void {
    this._pageNumber = 0;
    this.emitPageableChange();
  }

  paginatePrevious(): void {
    this._pageNumber -= 1;
    this.emitPageableChange();
  }

  paginateNext(): void {
    this._pageNumber += 1;
    this.emitPageableChange();
  }

  paginateLast(): void {
    this._pageNumber = this._totalPages;
    this.emitPageableChange();
  }

  isFirstPage(): boolean {
    return this._pageNumber === 0;
  }

  isLastPage(): boolean {
    return this._pageNumber + 1 === this._totalPages;
  }

  private emitPageableChange(): void {
    this.pageableChange.emit({
      offset: 0,
      pageSize: this._pageSize,
      pageNumber: this._pageNumber,
    });
  }

}
