import {createAction, props} from '@ngrx/store';
import {MappedHttpErrorResponse} from '@adnova/jf-ng-components';
import {KundeDTO, KundeRequestDTO} from '../../openapi/fakturierung-openapi';


export class KundeEntitiesActions {

  public static getKundeById = createAction(
    '[Kunde Entities] Load kunde by ID.',
    props<{
      betriebId: string;
      kundeId: string;
    }>(),
  );

  public static loadKundeByIdIfAbsent = createAction(
    '[Kunde Entities] Load absender by ID if absent.',
    props<{
      betriebId: string;
      kundeId: string;
    }>(),
  );

  public static getKundeByIdSuccess = createAction(
    '[Kunde Entities] Load kunde successfully.',
    props<{
      kundeDto: KundeDTO;
    }>(),
  );

  public static getKundeByIdFailure = createAction(
    '[Kunde Entities] Load kunde failed.',
    props<{
      error: any;
    }>(),
  );

  public static readKundenSuccess = createAction(
    '[Kunde Entities] Read list of kunden successfully.',
    props<{
      kundeDtos: KundeDTO[];
    }>(),
  );

  public static readKundenFailed = createAction(
    '[Kunde Entities] Read list of kunden failed.',
    props<{
      error: MappedHttpErrorResponse,
    }>(),
  );

  public static countKundenElementsSuccess = createAction(
    '[Kunde Effects] Count list of kunden elements successfully.',
    props<{
      totalElements: number,
    }>(),
  );

  public static countKundenPagesSuccess = createAction(
    '[Kunde Effects] Count list of kunden pages successfully.',
    props<{
      totalPages: number,
    }>(),
  );

  public static createKunde = createAction(
    '[Kunde Dialog] Create kunde.',
    props<{
      betriebId: string,
      requestDto: KundeRequestDTO,
      addToInvoice: boolean,
    }>(),
  );

  public static createKundeSuccess = createAction(
    '[Kunde Entities] Created kunde successfully.',
    props<{ kundeDto: KundeDTO }>(),
  );

  public static createKundeFailure = createAction(
    '[Absender Entities] Create kunde failed.',
    props<{ error: MappedHttpErrorResponse }>(),
  );

  public static updateKunde = createAction(
    '[Kunde Dialog] Update kunde.',
    props<{
      betriebId: string,
      kundeId: string,
      requestDto: KundeRequestDTO,
    }>(),
  );

  public static updateKundeSuccess = createAction(
    '[Kunde Entities] Updated kunde successfully.',
    props<{ kundeDto: KundeDTO }>(),
  );

  public static updateKundeFailure = createAction(
    '[Absender Entities] Updated kunde failed.',
    props<{ error: MappedHttpErrorResponse }>(),
  );

  public static deleteKunde = createAction(
    '[Kunde Dialog] Delete kunde.',
    props<{
      betriebId: string,
      kundeId: string,
    }>(),
  );

  public static deleteKundeSuccess = createAction(
    '[Kunde Entities] Deleted kunde successfully.',
    props<{
      kundeId: string,
    }>(),
  );

  public static deleteKundeFailure = createAction(
    '[Absender Entities] Delete kunde failed.',
    props<{ error: MappedHttpErrorResponse }>(),
  );

}
